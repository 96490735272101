<template>
    <div>
       
        <transition name="slide-fade">
            <div class="user-index-w">
                <div class="user-main">
                    <div class="user-index">
                        <div class="user-content flex flex-direction">
                            <div class="index-slider flex justify-between" v-loading="!showTransition">
                                <div class="slider-menu" v-if="showTransition">
                                    <div
                                        class="menu-item flex"
                                        :class="[hoverIndex === 0 ? 'cur' : '']"
                                        v-for="index in 9"
                                        :Key="`allIndustryList${index}`"
                                    >
                                        <div class="item-category flex">
                                            <div class="item-left flex">
                                                <div class="left-tit">
                                                    {{
                                                        allIndustryList[index - 1] &&
                                                            allIndustryList[index - 1].name
                                                    }}
                                                </div>
                                            </div>
                                            <div class="item-right">
                                                <i class="el-icon-arrow-right"></i>
                                            </div>
                                        </div>
                                        <div class="menu-sub">
                                            <div class="sub-title">
                                                {{
                                                    allIndustryList[index - 1] &&
                                                        allIndustryList[index - 1].name
                                                }}
                                            </div>
                                            <div
                                                class="sub-item"
                                                v-for="(row, index3) in allIndustryList[index - 1]
                                                    .children"
                                                :key="`sub${index3}`"
                                            >
                                                <div style="font-weight:bold; margin-bottom:8px; color:#222222;">{{row.name}}</div>
                                                <div
                                                    class="texts"
                                                    :style="{ marginTop: index3 > 0 ? '0px' : '' }"
                                                >
                                                    <span
                                                        v-for="(row1, index4) in row.children"
                                                        :key="`texts${index4}`"
                                                        @click="searchJobForSpan(row1)"
                                                    >
                                                        {{ row1.name }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel">
                                   
                                    <div
                                    style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff"
                                    class="swiper mySwiper2"
                                    >
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" v-for="(item, index) in figureList">
                                            <img :src="item.imgPath" @click="carouselClick(item)" />
                                            </div>
                                        </div>
                                        <div class="swiper-button-next"></div>
                                        <div class="swiper-button-prev"></div>
                                    </div>
                                        <div thumbsSlider="" class="swiper mySwiper">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" v-for="(item, index) in figureList">
                                            <img :src="item.imgPath" />
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="user-pane">
                                    <template v-if="userInfo && userInfo.userName && userInfo.roleId == 99">
                                        <div class="flex align-center">
                                            <div>
                                                <el-avatar v-if="!userInfo.heardImg" :size="62" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
                                                <el-avatar v-if="userInfo.heardImg" :size="62" :src="userInfo.heardImg"></el-avatar>
                                            </div>
                                            <div class="margin-left-xs">
                                                <strong class="text-xl" style="display:block; margin-bottom:8px;">{{ userInfo.userName }}</strong>
                                                <span class="text-grey">欢迎回来！</span>
                                            </div>
                                        </div>
                                        <div class="usertongji flex align-center justify-center">
                                            <div class="usertongjiitem cursor-pointer" @click="$router.push('/user/delivery')">
                                                <strong>{{delivery}}<em>次</em></strong>
                                                <p>投递</p>
                                            </div>
                                            <div class="usertongjiitem cursor-pointer" @click="$router.push('/user/interview')">
                                                <strong>{{interview}}<em>次</em></strong>
                                                <p>面试</p>
                                            </div>
                                        </div>
                                        <div class="margin-top-sm flex justify-between flex-wrap align-center">
                                            <div class="rightnavitem" @click="routeTo('/user/resumes')">
                                                <i style="background:#4ea5ff;"></i>
                                                <em>我的简历</em>
                                            </div>
                                            <div class="rightnavitem" @click="routeTo('/user/settingInfo')">
                                                <i style="background:#8cd579;"></i>
                                                <em>账号设置</em>
                                            </div>
                                            <div class="rightnavitem" @click="$router.push({ path: '/user/realname' })">
                                                <i style="background:#ffbf00;"></i>
                                                <em>实名认证</em>
                                            </div>
                                            <div class="rightnavitem" @click="routeTo('/user/videohome')">
                                                <i style="background:#ff8a66;"></i>
                                                <em>视频面试</em>
                                            </div>
                                            <div class="rightnavitem" @click="routeTo('/user/sendlist')">
                                                <i style="background:#49d6af;"></i>
                                                <em>消息管理</em>
                                            </div>
                                            <div class="rightnavitem" @click="routeTo('/user/smartjob')">
                                                <i style="background:#4fbbf1;"></i>
                                                <em>智能岗位</em>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="tit">欢迎登录</div>
                                        <div class="change-pane">
                                            <div class="pane-item change-active">个人用户</div>
                                            <div class="pane-item" @click="$router.push('/newLogin')">企业用户</div>
                                        </div>
                                        <el-input
                                            placeholder="请输入内容"
                                            v-model="phoneNumber"
                                            class="form-input input-with-select"
                                        >
                                        </el-input>
                                        <el-input
                                            type="password"
                                            class="form-input"
                                            placeholder="请输入密码"
                                            v-model="password"
                                            show-password
                                        ></el-input>
                                        <el-button class="login" :loading="btnLoading" @click="login">登录</el-button>
                                        <!-- <div class="agree-checkbox">
                                            <el-checkbox v-model="checked"></el-checkbox>
                                            已阅读并同意
                                            <span style="color:#3D7EFF">《用户服务协议》</span>
                                            和
                                            <span style="color:#3D7EFF">《隐私政策》</span>
                                        </div> -->
                                        <div class="forget-btn flex align-center justify-between">
                                            <span style="cursor:pointer;" @click="$router.push({path:'/newUserLogin',query:{type:2}})">还没有账号？立即注册</span>
                                            <span style="cursor:pointer;" @click="$router.push({path:'/newUserLogin',query:{type:1}})">忘记密码</span>
                                        </div>
                                    </template>
                                    

                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="user-body" v-if="longAd.length > 0 || shortAd.length > 0">
                        <div class="user-content">
                            <div class="merge-city-job" style="padding: 0px 0 50px 0">
                                <div class="myadbox">
                                    <div
                                        class="myadcont longad"
                                        v-for="(item, index) in longAd"
                                        :key="index"
                                    >
                                        <a :href="item.urlPath" target="_blank">
                                            <img :src="item.imgPath" alt="" />
                                        </a>
                                    </div>
                                    <div class="shortad" v-if="shortAd.length > 0">
                                        <div
                                            class="shortadcont"
                                            v-for="(item, index) in shortAd"
                                            :key="index"
                                        >
                                            <a :href="item.urlPath" target="_blank">
                                                <img :src="item.imgPath" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 热招职位==================begin -->
                    <div class="user-body">
                        <div class="user-content">
                            <div class="merge-city-job" style="padding-bottom: 44px" v-show="hotJobList.length>0">
                                <div class="job-title" id="hot">热招职位</div>
                                <!-- <h3>
                                    <span class="cur">IT 互联网</span>
                                    <span>金融</span>
                                    <span>房地产 建筑</span>
                                    <span>教育培训</span>
                                    <span>娱乐传媒</span>
                                    <span>医疗健康</span>
                                    <span>法律咨询</span>
                                    <span>采购贸易</span>
                                    <span>物流 供应链</span>
                                </h3> -->
                                <div class="cur-job-list flex" v-loading="!showTransition">
                                    <div
                                        class="job-item"
                                        v-for="(item, index) in hotJobList.slice(0, 9)"
                                        :key="`hotjob${index}`"
                                        :style="(index + 1) % 3 != 0 ? `margin-right:15px` : ``"
                                        @click="routeToJobDetail(item)"
                                    >
                                        <div class="item-top flex">
                                            <div class="top-company">
                                                <div class="company-name">{{ item.workName }}</div>
                                            </div>
                                            <div class="top-sales">{{ item.salaryName }}</div>
                                        </div>
                                        <div class="item-bottom flex">
                                            <div class="company-message flex">
                                                <span class="name text-overflow">
                                                    {{ item.companyName }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="show-more flex">
                                    <div class="more-btn" @click="routeTo('/user/searchJobs')">
                                        查看更多
                                    </div>
                                </div>
                            </div>


                            <!--热招职位================================================================================================end-->
                            <!-- 热门企业=====================================begin -->
                            <div class="merge-city-company" v-show="companyList.length>0">
                                <div class="company-title" id="hotqy">热门企业</div>
                                <div>
                                    <div class="company-list flex mylistitem" v-loading="!showTransition">
                                        <div
                                            class="company-item flex mycompanyitem"
                                            v-for="(item, index) in companyList"
                                            :key="`companyList${index}`"
                                            @click="openCompanyDetails(item)"
                                        >
                                            <div class="logo">
                                                <el-image
                                                style="width: 100px; height: 100px"
                                                :src="
                                                        item.logo ||
                                                            require('../../../assets/img/users/company_logo.png')
                                                    "
                                                fit="contain"></el-image>
                                            </div>
                                            <div>
                                                <div class="item-desc flex">
                                                    <div class="desc-name">{{ item.companyName }}</div>
                                                </div>
                                                <div class="company-job">
                                                    <span v-for="work in item.workList" :key="work.id">
                                                        {{ work.workName }}、
                                                    </span>
                                                </div>
                                                <div
                                                    class="about-info"
                                                    @click="openCompanyJobList(item)"
                                                >
                                                    <span class="tips">{{ item.workNumber }}</span>
                                                    个热招职位
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="common-tab-more flex">
                                    <div class="show-btn" @click="routeTo('/user/companyList')">
                                        查看更多
                                    </div>
                                </div>
                            </div>

                            <div class="user-body" v-show="employer.length>0">
                                <div class="user-content">
                                    <div class="merge-city-job">
                                        <div class="job-title">实力雇主</div>
                                        <div class="gzlist">
                                            <div
                                                class="gzlistitem"
                                                v-for="(item, index) in employer"
                                                @click="openCompanyDetails(item)"
                                                :key="index"
                                            >
                                                <div class="gzimg">
                                                    <div class="gzimgcont">
                                                         <el-image
                                            style="width: 390px; height: 160px"
                                            :src="item.logo"      
                                            fit="contain"></el-image>
                                                    </div>
                                                </div>
                                                <div style="margin:0 30px">
                                                    <div class="gzname">{{ item.companyName }}</div>
                                                    <div class="gztag">
                                                        <span
                                                            v-for="(sitem, sindex) in item.workList"
                                                            :key="sindex"
                                                        >
                                                            {{ sitem.workName }}
                                                        </span>
                                                    </div>
                                                    <div class="gzmes">
                                                        {{ item.companyNatureName }}｜{{
                                                            item.company
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 热门企业=====================================begin -->
                    </div>
                    <div class="user-body">
                        <div class="user-content">
                            <div class="merge-city-job">
                                <div class="job-title">资讯信息</div>
                                <div class="news">
                                    <div class="newsitem">
                                        <div class="newsitemtitle">
                                            <img src="@/assets/img/newIndex/news-gov.png" alt="" />
                                            <b>行政/事业/国企招聘专区</b>
                                            <router-link
                                                :to="{
                                                    path: '/user/news',
                                                    query: { cid: 93, t: '行政/事业/国企招聘专区' },
                                                }"
                                                type="info"
                                            >
                                                更多>>
                                            </router-link>
                                        </div>
                                        <ul>
                                            <li v-for="(item, index) in newsList1">
                                                <router-link
                                                    class="newslink"
                                                    :to="{
                                                        path: '/user/show',
                                                        query: { id: item.id },
                                                    }"
                                                    target="_blank"
                                                >
                                                    · {{ item.title }}
                                                </router-link>
                                                <span>
                                                    {{ item.creationTime.substring(5, 10) }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="newsitem">
                                        <div class="newsitemtitle">
                                            <img src="@/assets/img/newIndex/news-test.png" alt="" />
                                            <b>考试网上报名专区</b>
                                            <router-link
                                                :to="{
                                                    path: '/user/news',
                                                    query: { cid: 94, t: '考试网上报名专区' },
                                                }"
                                                type="info"
                                            >
                                                更多>>
                                            </router-link>
                                        </div>
                                        <ul>
                                            <li v-for="(item, index) in newsList2">
                                                <router-link
                                                    class="newslink"
                                                    :to="{
                                                        path: '/user/show',
                                                        query: { id: item.id },
                                                    }"
                                                    target="_blank"
                                                >
                                                    · {{ item.title }}
                                                </router-link>
                                                <span>
                                                    {{ item.creationTime.substring(5, 10) }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="newsitem">
                                        <div class="newsitemtitle">
                                            <img src="@/assets/img/newIndex/news.png" alt="" />
                                            <b>就业创业政策解读</b>
                                            <router-link
                                                :to="{
                                                    path: '/user/news',
                                                    query: { cid: 95, t: '人才市场专区' },
                                                }"
                                                type="info"
                                            >
                                                更多>>
                                            </router-link>
                                        </div>
                                        <ul>
                                            <li v-for="(item, index) in newsList3">
                                                <router-link
                                                    class="newslink"
                                                    :to="{
                                                        path: '/user/show',
                                                        query: { id: item.id },
                                                    }"
                                                    target="_blank"
                                                >
                                                    · {{ item.title }}
                                                </router-link>
                                                <span>
                                                    {{ item.creationTime.substring(5, 10) }}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="user-body">
                        <div class="user-content" v-show="companyVideo.length>0">
                            <div class="merge-city-job">
                                <div class="job-title">优秀企业</div>
                                <div class="videolist">
                                    <div
                                        class="videoitem"
                                        v-for="(item, index) in companyVideo"
                                        :key="index"
                                        @click="showplayfun(item)"
                                    >
                                        <div class="flex">
                                            <img
                                                class="company-logo"
                                                src="../../../assets/img/users/company_logo.png"
                                                alt=""
                                            />
                                            <div>
                                                <div class="videotitle">{{ item.companyName }}</div>
                                                <div class="videojob">
                                                    <span style="color:#FF5959;margin-right:4px">
                                                        {{ item.workNumber }}
                                                    </span>
                                                    个热招职位
                                                </div>
                                            </div>
                                        </div>

                                        <div class="videocont">
                                            <img :src="item.coverPicture" alt="" />
                                            <div class="ExploreEntModule_EntVideoPlayButton">
                                                <div
                                                    class="ExploreEntModule_EntVideoPlayButton_Arrow"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--   user=body   -->
                </div>

                <div class="showplay" v-if="showplay">
                    <div class="showplaybox">
                        <div class="showplayvideo">
                            <video class="gsvideo" controls :src="playurl"></video>
                        </div>
                        <div class="showplaytitle">
                            <el-link
                                class="shopplaytitlea"
                                :href="'/#/user/companyNews/gongsi?companyId=' + playid"
                                target="_blank"
                            >
                                {{ playname }}
                            </el-link>
                        </div>
                        <div class="playclose" @click="closeplay()">
                            <img src="../../../assets/Btn_Close.png" alt="" />
                        </div>
                    </div>
                    <div class="showplaybg"></div>
                </div>
            </div>

            <!--            <div v-if="showTransition" class="introduce-box">-->
            <!--                <div class="left">-->
            <!--                    <img class="image" src="" alt="">-->
            <!--                </div>-->
            <!--                <div class="right">-->
            <!--                    <p><span class="text-bold text-lg">上饶就业创业</span>成立于2002年2月，是直属广信区人力资源和社会保障局的市级公共就业服务机构。</p>-->
            <!--                    <br>-->
            <!--                    <p>上饶就业创业始终坚持以“促进和谐就业、打造群众满意窗口”为目标，面向广大劳动者和用人单位，以“实干、高效、创新”为宗旨开展各项就业服务，目前已形成了以职业介绍服务为龙头，集人力资源服务机构日常业务指导、异地务工人员就业管理、跨地区劳务交流协作、特困失业人员及随军家属就业服务、劳动保障事务代理、流动人员岗前综合教育等功能于一体的综合性公共就业服务窗口。</p>-->
            <!--                </div>-->
            <!--            </div>-->
        </transition>

        <!--        <div class="no-data" v-else>-->
        <!--            <div class="loading-data flex">-->
        <!--                <div class="loader-inner ball-pulse-rise">-->
        <!--                    <div></div>-->
        <!--                    <div></div>-->
        <!--                    <div></div>-->
        <!--                    <div></div>-->
        <!--                    <div></div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="user2">
            <el-carousel trigger="click" height="200px">
                <el-carousel-item v-for="(item, index) in figureList" :key="`figureList${index}`">
                    <div @click="carouselClick(item)" class="carousel-item">
                        <img :src="item.imgPath" alt="" />
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="user2Item">
                <span class="user2Item-title">资讯信息</span>
                <div class="user2Item-content">
                    <div class="newsitem">
                        <div class="newsitemtitle">
                            <b>行政/事业/国企招聘专区</b>
                            <router-link
                                :to="{
                                    path: '/user/news',
                                    query: { cid: 93, t: '行政/事业/国企招聘专区' },
                                }"
                                type="info"
                                style="width:30px;font-size: 12px"
                            >
                                更多
                            </router-link>
                        </div>
                        <ul>
                            <li v-for="(item, index) in newsList1">
                                <router-link
                                    class="newslink"
                                    :to="{ path: '/user/show', query: { id: item.id } }"
                                    target="_blank"
                                >
                                    · {{ item.title }}
                                </router-link>
                                <!-- <span>{{ item.creationTime.substring(5, 10) }}</span> -->
                            </li>
                        </ul>
                    </div>
                    <div class="newsitem">
                        <div class="newsitemtitle">
                            <b>考试网上报名专区</b>
                            <router-link
                                :to="{
                                    path: '/user/news',
                                    query: { cid: 94, t: '考试网上报名专区' },
                                }"
                                type="info"
                                style="width:30px;font-size: 12px"
                            >
                                更多
                            </router-link>
                        </div>
                        <ul>
                            <li v-for="(item, index) in newsList2">
                                <router-link
                                    class="newslink"
                                    :to="{ path: '/user/show', query: { id: item.id } }"
                                    target="_blank"
                                >
                                    · {{ item.title }}
                                </router-link>
                                <!-- <span>{{ item.creationTime.substring(5, 10) }}</span> -->
                            </li>
                        </ul>
                    </div>
                    <div class="newsitem">
                        <div class="newsitemtitle">
                            <b>就业创业政策解读</b>
                            <router-link
                                :to="{
                                    path: '/user/news',
                                    query: { cid: 95, t: '人才市场专区' },
                                }"
                                type="info"
                                style="width:30px;font-size: 12px"
                            >
                                更多
                            </router-link>
                        </div>
                        <ul>
                            <li v-for="(item, index) in newsList3">
                                <router-link
                                    class="newslink"
                                    :to="{ path: '/user/show', query: { id: item.id } }"
                                    target="_blank"
                                >
                                    · {{ item.title }}
                                </router-link>
                                <!-- <span>{{ item.creationTime.substring(5, 10) }}</span> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="user2Item">
                <span class="user2Item-title">热招职位</span>
                <div class="user2Item-content">
                    <div class="user2-job-list" v-loading="!showTransition">
                        <div
                            class="job-item"
                            v-for="(item, index) in hotJobList.slice(0, 9)"
                            :key="`hotjob${index}`"
                            @click="routeToJobDetail(item)"
                        >
                            <div>
                                <div class="text-bold">{{ item.workName }}</div>
                                <div class="top-sales text-red">{{ item.salaryName }}</div>
                                <div class="company-cate text-overflow">
                                    {{ item.workAreaDetail }}
                                </div>
                                <div>
                                    <el-tag size="mini" type="info">{{ item.workYears }}</el-tag>
                                    <el-tag size="mini" type="info">
                                        {{ item.workEducation }}
                                    </el-tag>
                                </div>
                            </div>
                            <div class="item-bottom flex">
                                <div class="company-message flex">
                                    <span class="name text-overflow">{{ item.companyName }}</span>
                                    <!--                                                <span class="type text-overflow">{{item.workWelfare}}</span>-->
                                    <!-- <span class="vline"></span>
                                                <span class="level">已上市</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="job-item">
                            <div
                                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                                @click="routeTo('/user/searchJobs')"
                            >
                                查看更多
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user2Item">
                <span class="user2Item-title">热门企业</span>
                <div class="user2Item-content">
                    <div class="user2-job-list" v-loading="!showTransition">
                        <div
                            class="company-item"
                            v-for="(item, index) in companyList"
                            :key="`companyList${index}`"
                            @click="openCompanyDetails(item)"
                        >
                            <div style="width: 50px; height: 50px">
                                <img
                                    style="width: 100%; height: 100%"
                                    :src="
                                        item.logo ||
                                            require('../../../assets/img/users/company_logo.png')
                                    "
                                    alt=""
                                />
                            </div>
                            <div class="item-desc flex">
                                <div class="desc-name">{{ item.companyName }}</div>
                            </div>
                            <div class="about-info" @click="openCompanyJobList(item)">
                                <span class="tips">{{ item.workNumber }}</span>
                                个热招职位
                            </div>
                        </div>
                        <div class="company-item">
                            <div
                                style="
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                                @click="routeTo('/user/companyList')"
                            >
                                查看更多
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user2Item">
                <span class="user2Item-title">实力雇主</span>
                <div class="user2Item-content">
                    <div class="user2-job-list" v-loading="!showTransition">
                        <div
                            class="gzlistItem"
                            v-for="(item, index) in employer"
                            @click="openCompanyDetails(item)"
                        >
                            <div class="gzImgcont">
                                <img class="gzImgcont" :src="item.logo" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Gotoup></Gotoup>
    </div>
</template>
<script>
import userRequest from '@/api/user';
import vueSeamlessScroll from 'vue-seamless-scroll';
import jobBlock from '@/pages/users/index/jobBlock';
import { log } from '../../../utils';
import Gotoup from '@/components/gotoUp.vue'
//import 'swiper/css/swiper.css'

export default {
    components: {
        vueSeamlessScroll,
        jobBlock,
        Gotoup
    },
    created(){
        let userInfo = window.localStorage.getItem('userInfo');
        userInfo && (this.userInfo = JSON.parse(userInfo));
        let roleId = this.userInfo.roleId;
        this.roleId = roleId;
        if(this.userInfo && this.roleId==99){
            console.log(66)
            this.getUsertotal()
        }

    },
    mounted() {
        
        //绑定轮播图和缩略图
        this.$nextTick(() => {
            // const swiperTop = this.$refs.swiperTop.$el.swiper;
            // const swiperThumbs = this.$refs.swiperThumbs.$el.swiper;
            // console.log('dwdwdwdwdwd', swiperTop, swiperThumbs);
            // swiperTop.controller.control = swiperThumbs;
            // swiperThumbs.controller.control = swiperTop;

            

        }); 
        // this.$emit('isReady', false)
        this.getIndexData();
        this.getAllIndustry();
        this.startScroll();
        this.getnewslist();
        this.getindustryClass();
    },
    data() {
        return {
            delivery:0,//投递数
            interview:0,//面试数
            roleId:null,
            userInfo:null,
            phoneNumber:"",
            password:"",
            searchselect: 1,
            industryClass: [],
            newsList1: [],
            newsList2: [],
            newsList3: [],
            showtoptab: 1,
            showplay: false,
            playurl: '',
            playname: '',
            playid: '',
            tcsearchshow: false,
            gzshow: 0,
            marginTopValue: 0, // 招聘会表格滚动的距离
            intervalObj: {}, // 招聘会滑动的对象
            showTransition: false, // 测试载入动画
            hoverIndex: -1,
            searchJobName: '', //职位搜索
            searchFixed: false,
            isPageLoading: false, // 页面是否正在加载
            categoryStatus: false, // 加载状态，动画
            allIndustryList: [], //行业分类
            figureList: [], //轮播图
            hotJobList: [], //热门职位
            companyList: [], //名企
            employer: [], //名企
            classificationList: [], //资讯分类
            companyVideo: [], //视频
            longAd: [], //长广告
            shortAd: [], //长广告
            indexSearch: {
                pageSize: 9,
                pageNum: 1,
            },
            jobFairList: [],
            hotSearchList: [
                {
                    title: '普工',
                },
                {
                    title: '电工',
                },
                {
                    title: '服务员',
                },
                {
                    title: '保安',
                },
                {
                    title: '保姆',
                },
                {
                    title: '司机',
                },
                {
                    title: '保洁',
                },
                {
                    title: '营业员',
                },
            ],
            swiperOptionTop: {
                autoplay:true,
                loop: true,
                // centeredSlides: true,
                // loopedSlides: 3, // looped slides should be the same
                spaceBetween: 10,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
            },
            swiperOptionThumbs: {
                loop: true,
                // loopedSlides: 6, // looped slides should be the same
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: 4,
                touchRatio: 0.2,
                slideToClickedSlide: true,
            },
            choiceIndex: 0,
            btnLoading:false, // 按钮加载图标
        };
    },
    computed: {
        classOption() {
            return {
                direction: 1,
                step: 0.3,
            };
        },
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },
    methods: {
        getUsertotal(){
            userRequest.queryUserHomeNumber().then(res=>{
                if(res.code==200){
                    this.delivery=res.data.delivery
                    this.interview=res.data.interview
                }
            })
        },
         userLogout() {
            this.$confirm('是否确定退出登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    userRequest
                        .userLogout()
                        .then(res => {
                            if (res.code === 200) {
                                window.localStorage.setItem('userInfo', JSON.stringify(''));
                                window.localStorage.setItem('token', JSON.stringify(''));
                                this.$message.success('退出成功~');
                                this.$router.push({ path: '/newUserLogin' });
                            }
                        })
                        .catch(err => {
                            this.$message.error(err);
                        });
                    // this.$message({
                    //     type: 'success',
                    //     message: '删除成功!'
                    // })
                })
                .catch(() => {});
        },
        routeTo(path) {
            if (path === this.$route.path) {
                return;
            }
            this.$router.push({
                path: path,
            });
        },
        login(){  // 登录成功跳转首页
            this.btnLoading = true
            userRequest.userLogin({phone: this.phoneNumber, password: this.password, type: 1}).then((res) => {
                // console.log("登录的返回值",res)
                if (res.code === 200) {
                    let userInfo = res.data
                    userInfo.roleId = 99
                    console.log('userInfo', userInfo)
                    window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
                    window.localStorage.setItem('roleId', userInfo.roleId)
                    window.localStorage.setItem("currentClient",'user')   // 标记，当前的客户端，user：用户端
                    window.localStorage.setItem("hasLogin", 'true')  // 标记，表示现在已经登录
                    this.$message.success("登录成功")
                    setTimeout(() => {
                        this.btnLoading = false
                        // this.$router.push({path: "/"}).catch(() => {});
                        location.reload()
                    }, 1000)
                }else{
                    this.btnLoading = false
                    this.$message.warning("登录失败，请检查账号或者密码是否正确")
                }
            }).catch(err=>{
                this.btnLoading = false
                this.$message.warning("登录失败，请检查账号或者密码是否正确")
            })

            setTimeout(()=>{
                this.btnLoading = false
            },5000)
        },
        getindustryClass() {
            userRequest.getindustryClass({}).then(res => {
                console.log(res);
                console.log(121212);
                this.industryClass = res.data;
            });
        },
        handleClick(e) {
            let zname = e.name;
            let zid = zname.substring(3) * 1;
            console.log(zid);
        },
        getnewslist() {
            userRequest.getHomeNewList({ }).then(res => {
                console.log(res);
                console.log(67676);
                this.newsList1 = res.data.newsList1;
                this.newsList2 = res.data.newsList2;
                this.newsList3 = res.data.newsList3;
            });
        },
        closeplay() {
            this.showplay = false;
        },
        showplayfun(row) {
            this.showplay = true;
            this.playurl = row.companyPropaganda;
            this.playname = row.companyName;
            this.playid = row.id;
        },
        showabout(e) {
            this.gzshow = e;
        },
        startScroll() {
            //  开始滚动
            this.intervalObj = setInterval(() => {
                let scrollHeight = this.jobFairList.length * 70 - 500; // 可滑动的高度
                if (Math.abs(this.marginTopValue) > scrollHeight) {
                    this.marginTopValue = 0;
                } else {
                    this.marginTopValue--;
                }
            }, 30);
        },
        stopScroll() {
            // 停止滚动
            clearInterval(this.intervalObj);
        },
        carouselClick(item) {
            // 轮播图点击
            window.open(item.urlPath, '_blank');
        },
        //获取行业分类
        getAllIndustry() {
            userRequest
                .getAllIndustry()
                .then(res => {
                    if (res.code === 200) {
                        this.allIndustryList = res.data;
                    }
                })
                .catch(() => {
                    this.showTransition = true;
                });
        },
        searchJob() {
            if (!this.searchJobName) {
                this.$message.error('关键词不能为空~');
                return;
            }
            if (this.searchselect == 1) {
                this.$router.push({
                    path: '/user/searchJobs',
                    query: {
                        workName: this.searchJobName,
                    },
                });
            } else if (this.searchselect == 2) {
                this.$router.push({
                    path: '/user/companyList',
                    query: {
                        key: this.searchJobName,
                    },
                });
            }
        },
        hotKeySearch(title) {
            console.log(title);
            this.$router.push({
                path: '/user/searchJobs',
                query: {
                    workName: title,
                },
            });
        },
        routeToJobDetail(item) {
            this.$router.push({
                path: '/user/jobDetail',
                query: {
                    workId: item.id,
                    workName: item.workName,
                },
            });
        },
        searchJobForSpan(row) {
            this.$router.push({
                path: '/user/searchJobs',
                query: {
                    workName: row.name,
                },
            });
        },
        getIndexData() {
            // 获取站点的网页数据
            userRequest.getIndexData(this.indexSearch).then(res => {
                console.log(res);
                console.log(8888);
                if (res.code === 200) {
                    this.figureList = res.data.img.reverse();

                     this.$nextTick(() => {
                         var swiper = new Swiper(".mySwiper", {
                            autoplay:true,//等同于以下设置
                            loop: true,
                            spaceBetween: 10,
                            slidesPerView: 4,
                            freeMode: true,
                            watchSlidesProgress: true,
                        });
                        var swiper2 = new Swiper(".mySwiper2", {
                            autoplay:true,//等同于以下设置
                            loop: true,
                            spaceBetween: 10,
                            navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                            },
                            thumbs: {
                            swiper: swiper,
                            },
                        });

                    });
    

                    this.hotJobList = res.data.works;
                    this.companyList = res.data.member;
                    this.classificationList = res.data.classificationList;
                    this.longAd = res.data.longAd;
                    this.shortAd = res.data.shortAd;
                    this.employer = res.data.employer;
                    this.companyVideo = res.data.companyVideo;
                    if (res.data.jobs && res.data.jobs.length > 0) {
                        let list = res.data.jobs;
                        list.forEach(res => {
                            let startDate = new Date(res.holdStartTime);
                            let endDate = new Date(res.holdEndTime);
                            if (startDate.getTime() === endDate.getTime()) {
                                //  同一天
                                res.time =
                                    startDate.getFullYear() +
                                    '年' +
                                    (startDate.getMonth() + 1) +
                                    '月' +
                                    startDate.getDate() +
                                    '日';
                            }
                            if (
                                startDate.getFullYear() === endDate.getFullYear() &&
                                startDate.getDate() === endDate.getDate() &&
                                startDate.getMonth() === endDate.getMonth()
                            ) {
                                //  同一天
                                res.time =
                                    startDate.getFullYear() +
                                    '年' +
                                    (startDate.getMonth() + 1) +
                                    '月' +
                                    startDate.getDate() +
                                    '日';
                            } else {
                                // console.log("开始时间年月日",res.holdStartTime,startDate.getFullYear(),startDate.getMonth()+1,startDate.getDate())
                                // console.log("结束时间年月日",res.holdEndTime,endDate.getFullYear(),endDate.getMonth()+1,endDate.getDate())
                                res.time =
                                    startDate.getFullYear() +
                                    '年' +
                                    (startDate.getMonth() + 1) +
                                    '月' +
                                    startDate.getDate() +
                                    '日-' +
                                    endDate.getFullYear() +
                                    '年' +
                                    (endDate.getMonth() + 1) +
                                    '月' +
                                    endDate.getDate() +
                                    '日';
                            }
                        });
                        this.jobFairList = list;

                        

                    }
                    this.showTransition = true;

                    this.$emit('isReady', true);
                }
            });
        },
        routeTo(path) {
            this.$router.push({
                path: path,
            });
        },
        openCompanyDetails(item) {
            this.$router.push({
                path: '/user/companyNews',
                query: {
                    companyId: item.id,
                },
            });
        },
        openCompanyJobList(item) {
            console.log('直接进入职位');
            this.$router.push({
                path: '/user/companyNews/cJobs',
                query: {
                    companyId: item.id,
                },
            });
        },
        changeSwiper(e) {
            console.log(e);
            this.choiceIndex = e.clickedIndex;
        },
    },
};
</script>
<style scoped>

 

.search-form-con {
    display: flex;
    align-items: center;
}
.search-form-con /deep/ .el-input__inner {
    background: none !important;
    border: none !important;
}
.search-form-con /deep/ .el-input-group__prepend {
    background: none !important;
    border: none !important;
}
</style>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
@import '../../../assets/css/loaders.min.css';


.usertongjiitem{ width: 40%; padding: 15px 0; text-align: center;}
.usertongjiitem strong{font-size: 40px; font-weight: normal; color: #298dff; padding: 12px 0; display: block;}
.usertongjiitem strong em{ font-size: 14px; font-style: normal; color: #666;}


.rightnavitem{ width: 60px; margin-bottom:15px; cursor: pointer; height: 74px; text-align: center;}
.rightnavitem i{ width: 36px; border-radius: 18px; height: 36px; display: inline-block;}
.rightnavitem em{ display: block; height: 38px; line-height: 38px; font-style: normal;}

.newsitem ul,
.newsitem ul li {
    margin: 0 !important;
    padding: 0 !important;
}
.toptab {
    height: 40px;
    display: flex;
    justify-content: space-between;
}
.toptabitem {
    cursor: pointer;
    width: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #eee;
    color: #555;
}
.toptabitemon {
    background: $main;
    color: #fff;
}
.showplay {
}
.topcompany {
    overflow: hidden;
}
.topcompany em {
    font-style: normal;
    margin-right: 4%;
    display: inline-block;
    width: 48%;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
}
.topcompany em:nth-child(even) {
    margin-right: 0;
}
.showplayvideo {
    height: 457px;
}
.gsvideo {
    height: 457px;
    width: 100%;
}
.showplaytitle {
    text-align: center;
    padding-top: 20px;
}
.shopplaytitlea {
    font-size: 18px;
    font-weight: bold;
    color: $main;
    display: inline-block;
}
.showplaybg {
    position: fixed;
    z-index: 6000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5) !important;
}
.showplaybox {
    position: fixed;
    z-index: 6500;
    top: 200px;
    width: 860px;
    box-sizing: border-box;
    padding: 15px;
    background: #ffffff;
    height: 540px;
    left: 50%;
    margin-left: -430px;
}
.playclose {
    position: absolute;
    width: 50px;
    height: 50px;
    right: -50px;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}
.hotkey {
    background: #ffffff;
    height: 205px;
    display: flex;
    justify-content: space-between;
}
.hotkeyitem {
    width: 33.333%;
    box-sizing: border-box;
    padding: 15px;
}
.hotkeyitem:nth-child(2) {
    border-left: #efefef solid 1px;
    border-right: #efefef solid 1px;
}
.hotkeylist {
    margin-top: 10px;
}
.hotkeytitle {
    display: inline-block;
    padding: 6px 10px;
    border-radius: 15px;
    color: #ffffff;
    background: $main;
}
.hotkeytitle2 {
    display: inline-block;
    font-weight: bold;
    padding: 6px 0px;
    border-radius: 15px;
    color: #333;
}
.hotkeylistitem {
    padding: 6px 0;
    display: inline-block;
    width: 33.333%;
}
.hotkeylistitem a {
    display: inline-block;
    padding: 5px 10px;
    border: #f0f0f0 solid 1px;
    text-decoration: none;
    font-size: 12px;
    border-radius: 15px;
    color: #555;
}
.hotkeylistitem a:hover {
    background: $main;
    border: $main solid 1px;
    color: #ffffff;
}
.videolist {
    display: flex;
    flex-wrap: wrap;
}
.videoitem {
    display: flex;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 390px;
    background: #ffffff;
    padding: 20px;
    padding-bottom: 22px;
    margin-right: 15px;
    .company-logo {
        width: 60px;
        height: 60px;
        margin-right: 12px;
        border-radius: 8px;
    }
}
.videoitem:nth-child(3n) {
    margin-right: 0;
}
.videotitle {
    font-size: 18px;
    width: 268px;
    line-height: 25px;
    margin-bottom: 8px;
    color: #333;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
}
.videojob {
    font-size: 16px;
    color: #666;
    line-height: 22px;
    text-align: left;
}
.videocont {
    margin-top: 15px;
    position: relative;
    height: 198px;
    overflow: hidden;
}
.videocont img {
    width: 100%;
    display: block;
    height: 198px;
    border-radius: 16px;
}
.companyspan {
    padding: 15px 0;
    margin-top: 15px;
}
.companyspan span {
    display: inline-block;
    padding: 3px 5px;
    border: #f0f0f0 solid 1px;
    font-size: 12px;
    color: #999;
    margin-right: 6px;
}
.gzlist {
    // background: #ffffff;
    min-height: 184px;
    display: flex;
    flex-wrap: wrap;
}
.gzlistitem {
    background: #ffffff;
    // position: relative;
    // vertical-align: middle;
    cursor: pointer;
    // display: inline-block;
    // width: 25%;
    // box-sizing: border-box;
    // height: 92px;
    // border-right: #f2f6fc solid 1px;
    // border-bottom: #f2f6fc solid 1px;
    width: 390px;
    height: 290px;
    margin: 0 15px 15px 0;
}
.gzlistitem:nth-child(3n) {
    margin-right: 0px;
}
.gzimg {
    height: 160px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.gzimg img {
    height: 160px;
    width: 390px;
}
.gzimgcont {
    text-align: center;
}
.gzimgcont img {
    height: 160px;
}
.gzshow {
    z-index: 1000;
    position: absolute;
    box-shadow: #aaa 0 0 8px;
    top: 0;
    left: 0;
    border: $main solid 1px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    background: #fff;
}
.gzname {
    font-size: 18px;
    font-weight: bold;
    line-height: 49px;
    color: #333333;
    margin-top: 2px;
}
.gztag {
    width: 350px;
    height: 30px;

    overflow: hidden;
}
.gztag {
}
.gztag span {
    margin-bottom: 5px;
    display: inline-block;
    padding: 0px 11px;
    font-size: 12px;
    border: #d9dce1 solid 1px;
    margin-right: 10px;
    border-radius: 2px;
    line-height: 27px;
    color: #9a9fa7;
}
.gzmes {
    font-size: 14px;
    line-height: 20px;
    color: 14px;
    font-weight: 400;
    margin-top: 14px;
}
.news {
    box-sizing: border-box;
    padding: 0px;
    justify-content: space-between;
    display: flex;
}
.el-tabs--border-card {
    box-shadow: none !important;
}
.newsitem {
    width: 390px;
    background: #ffffff;
    box-sizing: border-box;
    padding: 20px 25px;
}
.newsitem ul {
    display: block;
}
.newsitemtitle {
    padding: 0;
    align-items: center;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }
}
// .newsitemtitle::before {
//     content: '';
//     width: 3px;
//     height: 12px;
//     background: $main;
// }
.newsitemtitle b {
    width: 220px;
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-right: 20px;
}
.newsitemtitle a {
    text-decoration: none;
    color: #747774;
    font-weight: 400;
    font-size: 14px;
}
.newsitem ul li {
    display: flex;
    height: 30px;
    line-height: 30px;
    justify-content: space-between;
}
.newsitem ul li span {
    font-size: 14px;
    color: #9a9fa7;
}
a.newslink {
    text-align: left;
    height: 30px;
    width: 276px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #333;
}
a.newslink:hover {
    color: #5dd5c8;
}
.el-link {
    display: block !important;
}
.myadbox {
    background: #ffffff;
    padding: 15px 15px;
}
.longad img {
    width: 100%;
    cursor: pointer;
}
.myadcont {
}
.shortad {
    display: flex;
    justify-content: space-between;
}
.shortadcont {
    box-sizing: border-box;
    width: 50%;
    padding: 0px 0;
}
.shortadcont img {
    width: 100%;
    cursor: pointer;
}
.longad {
    margin-bottom: 14px;
}
.shortadcont:nth-child(odd) {
    margin-right: 7px;
}
.shortadcont:nth-child(even) {
    margin-left: 7px;
}
.ExploreEntModule_EntVideoPlayButton {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    border-radius: 100%;
    width: 40px;
    height: 40px;
}
.ExploreEntModule_EntVideoPlayButton_Arrow {
    position: absolute;
    border-left: 10px solid #fff;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 50%;
    left: 50%;
    transform: translate(-35%, -50%);
}
.tcsearch {
    width: 100%;
    box-shadow: #eaeaea 4px 4px 8px;
    box-sizing: border-box;
    padding: 15px;
    background: #ffffff;
    position: absolute;
    top: 50px;
    z-index: 5000;
}

/*载入动画测试=================begin*/
.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.seamless-main {
    overflow: hidden;
}

.table-box {
    height: 400px;
    border-radius: 4px;
    /*border: 2px solid red;*/
    overflow: hidden;
    border: 1px solid #c3c3c3;
    /*margin-bottom: 20px;*/

    &-header {
        /*background-color: #4a4160;*/
        background-color: #20aa90;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        height: 50px;
        z-index: 999;

        .header-item {
            font-size: 16px;
            color: #ffffff;
            font-weight: bold;
        }
    }

    &-body {
        /*background-color: #e2e2e2;*/
        height: 250px;
        width: 100%;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow: hidden;

        &::-webkit-scrollbar {
            display: none;
        }

        .body-item {
            height: 70px;
            display: flex;
            align-items: center;
            text-align: center;

            div {
                padding: 20px;
            }

            .date {
                width: 25%;
            }

            .job-title {
                width: 25%;
                line-height: 1.6em;
            }

            .type {
                width: 25%;
            }

            .address {
                width: 25%;
            }

            &:nth-child(odd) {
                background-color: #ffffff;
            }

            &:nth-child(even) {
                background-color: #eff6f1;
            }
        }
    }
}

.user-index-title {
    font-size: 24px;
    text-align: center;
    position: relative;
    margin-bottom: 24px;
    color: #333333;

    &::before {
        content: '';
        display: inline-block;
        vertical-align: 8px;
        margin: 0 10px;
        width: 50px;
        height: 1px;
        background: #d1d4db;
    }

    &::after {
        content: '';
        display: inline-block;
        vertical-align: 8px;
        margin: 0 10px;
        width: 50px;
        height: 1px;
        background: #d1d4db;
    }
}

/*载入动画测试结束=========================*/
.user-index-w {
    /*position: relative;*/
    /*margin-top: 80px;*/

    .no-data {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: #000, $alpha: 0.4);
        z-index: 10000;

        .loading-data {
            position: absolute;
            justify-content: center;
            align-items: center;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 200px;
            height: 200px;
            // background: #fff;
        }
    }
}

.user-index {
    /*background-color: #FFFFFF;*/
    background-color: #f4f6f9;
    background-size: 100% 700px;
    background-repeat: no-repeat;
    padding: 10px 0 0 0;
    box-sizing: border-box;
    padding-bottom: 30px;
}

.user-body {
    background-color: #f4f6f9;
}

.introduce-box-position {
    margin-top: 800px;
    height: 0px;
    width: 100%;
}

.introduce-box {
    min-height: 200px;
    width: 1200px;
    margin-bottom: 50px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 8px 1px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 650px;
    margin-left: -600px;

    .left {
        width: 20%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .image {
            width: 209px;
            height: 148px;
            object-fit: cover;
        }
    }

    .right {
        width: 80%;
        padding: 30px;
        white-space: pre-wrap;
        line-height: 1.4em;

        p {
            text-indent: 2em;
        }
    }
}

.index-search {
    margin-top: 20px;
    // &.fixed{
    //     position: fixed;
    //     z-index: 10000;
    //     top: 0;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     background: #fff;
    //     margin-top: 0;
    //     .search-hot{
    //         display: none;
    //     }
    // }
    .search-box {
        width: 934px;
        margin: 0 auto;

        .search-form {
            height: 50px;

            .search-form-con {
                box-sizing: border-box;
                float: left;
                width: 794px;
                height: 50px;
                box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.13);

                &:hover {
                    border: 1px solid $main;
                }

                .form-input {
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    padding: 0 15px;
                    border: none;
                    outline: none;
                    font-size: 16px;
                }
            }

            .search-btn {
                float: left;
                width: 140px;
                height: 50px;
                color: #fff;
                font-size: 20px;
                text-align: center;
                line-height: 50px;
                cursor: pointer;
                background: $main;

                &:hover {
                    background: $hover;
                }
            }
        }
    }
}

.index-slider {
    position: relative;
    display: flex;
    // padding-left: 230px;
    // margin-top: 10px;
    height: 410px;
    width: 1200px;

    .slider-menu {
        position: relative;
        // width: 220px;
        flex: 0 0 220px;
        height: 410px;
        max-height: 410px;
        // overflow: hidden;
        box-sizing: border-box;
        top: 0;
        left: 0;
        background: #fff;
        color: #61687c;
        z-index: 50;
        box-shadow: 5px 5px 15px rgba(3, 0, 0, 0.04);

        .menu-item {
            // position: relative;

            &:hover {
                .menu-sub {
                    // display: block;
                    visibility: visible;
                    transform: translate3D(0, 0, 0);
                }
            }

            &.cur {
                .item-category {
                    background: $main;
                    color: #fff;

                    .left-tit {
                        color: #fff !important;
                    }
                }
            }

            .item-category {
                padding: 9px 20px 10px;
                width: 100%;
                justify-content: space-between;
                height: 45px;
                font-size: 14px;
                line-height: 24px;
                overflow: hidden;

                border-top: #fff solid 1px;
                border-bottom: #fff solid 1px;
                &:hover {
                    color: #298dff;
                    // border-top: #e6e8ec solid 1px;
                    // border-bottom: #e6e8ec solid 1px;
                    .left-tit {
                        color: #298dff !important;
                    }
                }

                .item-left {
                    .left-tit {
                        font-weight: 400;
                        color: #333;
                        font-size: 14px;
                    }

                    .left-con {
                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .menu-sub {
                // display: none;
                visibility: hidden;
                position: absolute;
                background: #fff;
                // padding: 20px 30px 20px 10px;
                transform: translate3D(3px, -3px, 0);
                width: 715px;
                top: 0;
                left: 100%;
                z-index: 100;
                box-shadow: 0 1px 6px rgba(6, 0, 1, 0.05);
                // transition: all 0.1s;

                .sub-title {
                    margin: 0 20px;
                    line-height: 40px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    border-bottom: 1px solid #e6e8ec;
                }

                .sub-item {
                    padding-left: 20px;
                    margin-top: 7px;
                    padding-bottom: 10px;
                    h4 {
                        width: 104px;
                        color: #8d92a1;
                        font-size: 13px;
                    }

                    .texts {
                        width: 675px;
                        line-height: 33px;
                        font-size: 14px;
                        display: flex;
                        flex-wrap: wrap;
                        // border-bottom: solid 1px #f0f0f0;
                        padding: 0 0 3px 1px;

                        span {
                            width: 166px;
                            color: #747774;
                            font-size: 14px;
                            cursor: pointer;

                            &:hover {
                                color: #298dff;
                            }
                        }
                    }
                }
            }
        }
    }

    .carousel {
        width: 700px;
        margin-left: 10px;
        height: 410px;
        .bottom {
            background-color: #fff;
            padding: 0px 11px;
            margin-top: 12px;
        }

        .carousel-item {
            width: 848px;

            img {
                width: 100%;
                height: 340px;
            }
        }
    }
}

.hot-search-box {
    display: flex;
    padding: 15px 0;
    flex-direction: row;
    align-items: center;

    .hot-search-title {
        font-size: 14px;
        margin: 0px 20px;
    }

    .hot-search-tag-box {
        display: flex;
        flex-direction: row;
        align-items: center;

        .hot-search-tag-item {
            margin-right: 20px;

            .hot-search-tag-button {
                font-size: 16px;
            }
        }
    }
}

.merge-city-job {
    padding-bottom: 30px;
    // min-height: 400px;
    /*background-color: #FFFFFF;*/
    .job-title {
        font-size: 24px;
        text-align: center;
        position: relative;
        margin-bottom: 30px;
        font-weight: bold;
        color: #333333;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: 8px;
            margin: 0 10px;
            width: 50px;
            height: 1px;
            background: #d1d4db;
        }

        &::after {
            content: '';
            display: inline-block;
            vertical-align: 8px;
            margin: 0 10px;
            width: 50px;
            height: 1px;
            background: #d1d4db;
        }
    }

    h3 {
        width: 1180px;
        display: flex;
        justify-content: space-around;
        // box-sizing: border-box;
        height: 50px;
        position: relative;
        padding-left: 20px;
        background: #fff;
        margin-bottom: 10px;
        margin-right: -77px;

        span {
            box-sizing: border-box;
            position: relative;
            display: inline-block;
            // margin-right: 73px;
            height: 50px;
            line-height: 50px;
            color: #333;
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;

            &.cur {
                color: #333;
                font-weight: 400;
                border-bottom: 3px solid #fdd000;
            }

            &:hover {
                color: #333;
            }
        }
    }

    .cur-job-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-around;
        // min-height: 400px;
        padding-bottom: 30px;

        .job-item {
            cursor: pointer;
            box-sizing: border-box;
            height: 150px;
            width: 390px;
            float: left;
            padding: 20px;
            margin-bottom: 15px;
            background: #fff;
            // flex: 1;
            /*&:nth-child(n+3) {*/
            /*    margin-right: 0;*/
            /*}*/

            &:hover {
                box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.08);

                .company-name {
                    color: $main !important;
                }
            }

            .item-top {
                .top-company {
                    .company-name {
                        max-width: 200px;
                        font-size: 20px;
                        color: #333;
                        margin-right: 6px;
                        font-weight: bold;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin-bottom: 10px;
                    }

                    .company-cate {
                        font-size: 12px;
                        color: #8d92a1;

                        .line {
                            width: 1px;
                            height: 12px;
                            vertical-align: middle;
                            background: #e0e0e0;
                            margin: 0 10px;
                        }
                    }
                }

                .top-sales {
                    flex: 1;
                    font-size: 20px;
                    text-align: right;
                    font-weight: 700;
                    color: $other;
                }
            }

            .item-bottom {
                // border-top: 1px solid #eef0f5;
                align-items: center;
                display: flex;
                // margin-top: 5px;
                padding-top: 20px;

                .company-logo {
                    border: 1px solid #f3f5fb;
                    margin-right: 15px;
                    border-radius: 4px;
                    border-radius: 16px;

                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 15px;
                    }
                }

                .company-message {
                    .name {
                        color: #61687c;
                        max-width: 250px;
                        min-width: 120px;
                        font-size: 18px;
                        // max-width: 200px;
                    }

                    .type {
                        font-size: 13px;
                        // max-width: 90px;
                        max-width: 160px;
                        color: #8d92a1;
                    }

                    .vline {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        vertical-align: middle;
                        background: #e0e0e0;
                        margin: 0 10px;
                    }

                    .level {
                        font-size: 13px;
                        max-width: 80px;
                        color: #8d92a1;
                    }
                }
            }
        }
    }

    .show-more {
        margin-top: 14px;
        justify-content: center;

        .more-btn {
            cursor: pointer;
            width: 390px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: $main;
            font-size: 16px;
            color: #fff;

            &:hover {
                background: $hover;
            }
        }
    }
}

.merge-city-company {
    /*margin-top: 200px;*/
    padding-bottom: 50px;
    min-height: 400px;
    /*background-color: #FFFFFF;*/
    .company-title {
        margin-bottom: 30px;
        font-size: 24px;
        text-align: center;
        /*color: #FFFFFF;*/
        color: #333;
        font-weight: bold;
        &::before {
            content: '';
            display: inline-block;
            vertical-align: 8px;
            margin: 0 10px;
            width: 50px;
            height: 1px;
            background: #d1d4db;
        }

        &::after {
            content: '';
            display: inline-block;
            vertical-align: 8px;
            margin: 0 10px;
            width: 50px;
            height: 1px;
            background: #d1d4db;
        }
    }

    .company-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        min-height: 400px;
        padding-bottom: 30px;

        .company-item {
            cursor: pointer;
            height: 130px;
            flex-direction: row;
            align-items: center;
            // width: 350px;
            flex:0 0 350px;
            margin: 0 21px 15px 0;
            padding: 20px;
            background: #fff;
            font-weight: bold;
            &:hover {
                box-shadow: 0 3px 6px 2px rgba(0, 0, 0, 0.08);

                .desc-name {
                    color: $main !important;
                    font-weight: bold;
                }
            }

            &:nth-of-type(3n + 1) {
                margin-right: 0;
            }

            .logo {
                width: 100px;
                height: 100px;
                text-align: center;
                overflow: hidden;
                margin-right: 30px;
                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 8px;
                }
            }
            .company-job {
                width: 215px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 4px;
                color: #666666;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .item-desc {
                // text-align: center;
                flex-direction: column;
                // align-items: center;

                .desc-name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    max-width: 216px;
                    margin-bottom: 10px;
                    line-height: 22px;
                    font-size: 18px;
                    color: #333;
                }

                .desc-text {
                    height: 18px;
                    line-height: 18px;
                    color: #898e9d;
                    font-size: 12px;

                    .vline {
                        display: inline-block;
                        width: 1px;
                        height: 12px;
                        vertical-align: middle;
                        background: #e0e0e0;
                        margin: 0 10px;
                    }
                }
            }

            .about-info {
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                color: #333333;
                margin-top: 16px;
                .tips {
                    color: #ff5959;
                }
            }
        }

    }

    .common-tab-more {
        justify-content: center;
        margin-top: 10px;

        .show-btn {
            cursor: pointer;
            width: 390px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            background: $main;
            color: #fff;

            &:hover {
                background: $hover;
            }
        }
    }
}

.article-list-box {
    margin: 0px auto;
    height: 280px;
    padding-top: 50px;
    background-color: #d3f3fe;
    display: flex;
    flex-direction: row;

    .left-carousel-box {
        width: 600px;
    }

    .article-list-cell {
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        padding: 0px 20px;
    }
}
.user2 {
    display: none;
}

@media screen and (max-width: 800px) {
    * {
        box-sizing: border-box;
    }
    .user-index-w {
        display: none;
    }
    .user2 {
        display: block;
        .carousel-item {
            width: 100vw;
            img {
                width: 100%;
                height: 200px;
            }
        }
        .user2Item {
            width: 100%;
            height: auto;
            .user2Item-title {
                width: 100%;
                height: 50px;
                background-color: #d3f3fe;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .user2Item-content {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                .newsitem {
                    width: 90%;
                    maring-right: 1%;
                    background: #fff;
                    box-sizing: border-box;
                    padding: 15px;
                }
                b {
                    width: 300px;
                    display: block;
                    font-weight: 700;
                    font-size: 16px;
                    color: #555;
                }
                a {
                    color: #999;
                    font-weight: normal;
                }
                a.newslink {
                    text-align: left;
                    height: 30px;
                    width: 320px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-decoration: none;
                    color: #555;
                }
                .newsitemtitle:before {
                    content: '';
                    width: 3px;
                    height: 12px;
                    background: #5dd5c8;
                }
                .user2-job-list {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 5%;
                    justify-content: space-between;
                    .job-item {
                        width: 48%;
                        margin-bottom: 20px;
                        background-color: #ffffff;
                        border-radius: 10px;
                        padding: 10px;
                        font-size: 12px;
                        line-height: 20px;
                    }
                    .company-item {
                        width: 48%;
                        margin-bottom: 20px;
                        background-color: #ffffff;
                        border-radius: 10px;
                        padding: 10px;
                        font-size: 12px;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .gzlistItem {
                        width: 50%;
                        background-color: #ffffff;
                        border: 1px solid #e4e4e4;
                        padding: 20px;
                        font-size: 12px;
                        line-height: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .gzImgcont {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

.swiper {
        width: 700px;
        height: 300px;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }


      .swiper {
        width: 700px;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .mySwiper2 {
        height:300px;
        width: 700px;
      }

      .mySwiper {
        height: 98px;
        background: #ffffff;
        box-sizing: border-box;
        padding: 10px;
        margin-top: 12px;
      }

      .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
      }

      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

.user-pane {
    // width: 220px;
    flex:0 0 260px;
    height: 410px;
    padding: 14px 20px 0 20px;
    background-color: #fff;
    margin-left: 10px;
    .tit {
        line-height: 84px;
        font-size: 20px;
        color: #333;
        text-align: center;
        font-weight: 800;
    }
    .change-pane {
        display: flex;
        .pane-item {
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            color: #333333;
            margin-right: 22px;
            cursor: pointer;
            &.change-active {
                &::after {
                    margin-top: 4px;
                    display: block;
                    content: ' ';
                    width: 64px;
                    background-color: #47a2fb;
                    height: 3px;
                }
            }
        }
    }
}
.mycompanyitem{margin-right: 21px;}
.mycompanyitem:nth-child(3n){margin-right: 0;}

.form-input {
    margin-top: 18px;
}
::v-deep .el-input__inner {
    height: 32px;
    background-color: #fff;
    font-size: 12px;
}
::v-deep .el-select {
    width: 68px;
}
::v-deep .el-button {
    background-color: #298dff;
    color: #fff;
    width: 100%;
    height: 36px;
    margin-top: 18px;
}
.agree-checkbox {
    margin-top: 24px;
    font-size: 12px;
    line-height: 18px;
}
.forget-btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 37px;
    color: #3d7eff;
    margin-top: 20px;
    text-align: right;
}

.mylistitem:nth-child(3n){ margin-right: 0!important;}


</style>
