<template>
    <!--
        name:职位模块
        desc:首页的职位模块
        createTime:2021-01-24
        updateTime:
        author:AB
    -->
    <div class="container">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{title}}</span>
<!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
            <div class="tag-box">
                <div class="tag-wrapper" v-for="(item,index) in 12" :key = "index">
                    <el-button size="mini" plain  round>岗位名</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    export default {
        name:'jobBlock',
        props:{
            title:{
                type:String
            }
        },
        created(){
            console.log("收到的标题",this.title)
        },
        mounted(){

        },
        data(){
            return{

            }
        },
        methods:{

        }
    }
</script>

<style lang="scss" scoped>
    .container{

    }

    .box-card{
        width: 350px;
        /*margin-right: 50px;*/
    }

    .tag-box{
        padding: 10px 10px 20px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .tag-wrapper{
            padding: 5px;
            flex: 1;
        }
    }
</style>