<template>
    <div class="gotoup">
        <a href="#hot" class="gotoitem flex align-center justify-center">
            <el-tooltip class="item" effect="dark" content="热招职位" placement="top">
                <i class="el-icon-tickets"></i>
            </el-tooltip>
        </a>
        <a href="#hotqy" class="gotoitem flex align-center justify-center">
            <el-tooltip class="item" effect="dark" content="热门企业" placement="top">
                <i class="el-icon-office-building"></i>
            </el-tooltip>
        </a>
        <div class="gotoitem flex align-center justify-center" @click="gotoTop">
            <i class="el-icon-arrow-up"></i>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{

        }
    },
    mounted(){
        
    },
    methods:{
        gotoTop(){
            scrollTo(0,0);
        }
    }
}
</script>

<style scoped>
.gotoup{
    background: #ffffff;
    width: 48px;
    height: auto;
    border-radius: 24px;
    position: fixed;
    left: 50%;
    bottom: 200px;
    padding: 10px 0;
    margin-left: 610px;
    box-shadow: rgba(0,0,0,0.05) 0 0 10px;
    overflow: hidden;
}

.gotoitem{ width: 48px; height: 48px; cursor: pointer; font-size: 24px; text-decoration: none;}
.gotoitem i{ color:#bababa;}
.gotoitem:hover{
    background: #f9f9f9;
}

</style>
